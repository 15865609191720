.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/images/bg-maintenance.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.message {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 50px;
  border-radius: 10px;
}
